import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import AutoGallery from "../components/AutoGallery";
import Restaurant from "../components/Restaurant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "@reach/router";

export const RestaurantPageTemplate = ({ title, description, restaurants }) => {
  const location = useLocation();

  return (
    <React.Fragment>
      <section className="section">
        <div className="container mb-5">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/do">Things to do</Link>
              </li>
              <li className="is-active">
                <a href={location.pathname} aria-current="page">
                  {title}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="container">
          <h3 className="subtitle is-2">{title}</h3>
          <div className="content">
            <p className="mb-4">{description}</p>
          </div>
          <h4 className="subtitle is-4">Key</h4>
          <div className="content">
            <article className="message is-info">
              <div className="message-body">
                <span className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon icon={faInfo} />
                  </span>
                  <span>
                    Walking + driving distances are from Residence Inn at
                    Marriott
                  </span>
                </span>
              </div>
            </article>
            <table className="table is-narrow">
              <tbody>
                <tr>
                  <th>$$</th>
                  <td>$12-18 per plate</td>
                </tr>
                <tr>
                  <th>$$$</th>
                  <td>$18-25 per plate</td>
                </tr>
                <tr>
                  <th>$$$$</th>
                  <td>$25 + per plate</td>
                </tr>
                <tr>
                  <th>V</th>
                  <td>Veggie focused/friendly</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {restaurants.map(
        ({ name, url, location, line1, line2, bullets, images }) => {
          return (
            <Restaurant
              key={name}
              name={name}
              url={url}
              location={location}
              line1={line1}
              line2={line2}
              bullets={bullets}
              images={images}
            />
          );
        }
      )}
    </React.Fragment>
  );
};

RestaurantPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
};

const RestaurantPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout title={frontmatter.title}>
      <AutoGallery>
        <RestaurantPageTemplate
          title={frontmatter.title}
          description={frontmatter.description}
          restaurants={frontmatter.restaurants}
        />
      </AutoGallery>
    </Layout>
  );
};

RestaurantPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default RestaurantPage;

export const restaurantPageQuery = graphql`
  query RestaurantPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        restaurants {
          name
          url
          location
          line1
          line2
          bullets
          images
        }
      }
    }
  }
`;
