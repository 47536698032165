import React from "react";
import ReactMarkdown from "react-markdown";

const Address = ({ line1, line2 }) => {
  return (
    <div className="block">
      <a target="_blank" href={`https://maps.google.com/?q=${line1} ${line2}`}>
        {line1}
        <br />
        {line2}
      </a>
    </div>
  );
};

const Images = ({ alt, images }) => {
  if (images.length === 1) {
    return (
      <div className="tile">
        <img alt={alt} src={`/img/restaurants/${images[0]}`} />
      </div>
    );
  } else if (images.length === 2) {
    return (
      <div className="tile">
        <div className="tile is-parent is-vertical">
          <div className="tile is-child">
            <img alt={alt} src={`/img/restaurants/${images[0]}`} />
          </div>
        </div>
        <div className="tile is-parent is-vertical">
          <div className="tile is-child">
            <img alt={alt} src={`/img/restaurants/${images[1]}`} />
          </div>
        </div>
      </div>
    );
  } else if (images.length === 4) {
    return (
      <div className="tile">
        <div className="tile is-parent is-vertical">
          <div className="tile is-child">
            <img alt={alt} src={`/img/restaurants/${images[0]}`} />
          </div>
          <div className="tile is-child">
            <img alt={alt} src={`/img/restaurants/${images[1]}`} />
          </div>
        </div>
        <div className="tile is-parent is-vertical">
          <div className="tile is-child">
            <img alt={alt} src={`/img/restaurants/${images[2]}`} />
          </div>
          <div className="tile is-child">
            <img alt={alt} src={`/img/restaurants/${images[3]}`} />
          </div>
        </div>
      </div>
    );
  } else {
    throw new Error(
      `Unsupported number of images for restaurant: ${alt} (total images: ${images.length})`
    );
  }
};

const Restaurant = ({ name, url, location, line1, line2, bullets, images }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-one-third">
            <h4 className="subtitle is-4">
              <a href={url}>{name}</a>{" "}
              <span className="tag is-success">{location}</span>
            </h4>
            <Address line1={line1} line2={line2} />
            <div className="content">
              <ul>
                {bullets.map((bullet, i) => (
                  <li key={i}>
                    <ReactMarkdown>{bullet}</ReactMarkdown>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="column">
            <Images alt={name} images={images} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Restaurant;
